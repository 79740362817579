<template>
  <div class="base-container">
    <div class="hd">
      <span class="page-title"><i class="iconfont" :class="{ 'icon-yuangongxiangqing': page === '员工详情', 'icon-gongdanxiangqing': page === '事项详情' }"></i>{{ page }}</span>
      <el-button class="file-back" @click="goBack" v-if="back">返回</el-button>
      <div class="">
        <el-button type="primary" class="iconfont icon-dcicon_save1" @click="subimit" v-if="submit">保存</el-button>
        <el-button class="iconfont icon-dcx" @click="goBack" v-if="cancel">取消</el-button>
      </div>
      <slot name="customBtns"></slot>
    </div>
    <div class="main">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    back: Boolean,
    page: String,
    submit: Boolean,
    cancel: Boolean,
    btns: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    subimit() {
      this.$emit('submit');
    }
  }
};
</script>

<style lang="scss" scoped>
.base-container {
  background: #fff;
  border-radius: 6px;
  padding: 20px;
}
.main {
  // margin-top: 15px;
  padding: 20px 0 !important;
}
.hd {
  width: 100%;
  position: relative;
  z-index: 10;
}
.file-back {
  position: absolute;
  right: 0;
  top: -10px;
}
.page-title {
  font-size: 16px;
  display: flex;
  align-items: center;
  .iconfont {
    font-size: 24px;
    color: #f39800;
  }
  .icon-gongdanxiangqing {
    font-size: 16px;
    margin-right: 5px;
  }
}
</style>
